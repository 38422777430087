module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../plugins/wm-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://developers.launchdarkly.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Feature Flag & Toggle Management | LaunchDarkly","short_name":"LaunchDarkly","description":"LaunchDarkly provides simple, scalable feature flag, toggle management (feature management) & experimentation for the modern enterprise. Eliminate risk, deliver value.","start_url":"/","background_color":"#191919","theme_color":"#405BFF","display":"standalone","icon":"src/images/icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"17d478ce54f090941be262dd6d0a2a68"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
